import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";

import TableStyled from "../common/TableStyled/TableStyled";
import Layout from "../Components/Layout";
import Section from "../common/Section/Section";
const links = ["トップ", "採用情報"];

const RecruitPage = props => {
  return (
    <>
      <Layout
        links={links}
        title="Recruit"
        subTitle="採用情報"
        seoTitle="採用情報"
        seoDescrption="スタッフ募集中！狭山スイミングプールで一緒に働きませんか。"
        seoKeywords="求人,スタッフ募集,所沢,狭山市,スポーツ,運動,狭山市バイト,高い時給"
      >
        <Section>
          <Container>
            <Row>
              <Col sm={6}>
                <Card bg="light" style={{ width: "100%" }}>
                  <Card.Img
                    variant="top"
                    src="https://images.prismic.io/sayama-swimming-club/967c2647-b119-44a1-aeed-66fe625727de_sayama-swimming-club-recruit.JPG?auto=compress,format"
                  />
                  <Card.Body>
                    <Card.Title>採用情報</Card.Title>
                    <Card.Text>
                      未経験でもOK！やる気のある方まってます！子供たちの成長を間近で感じてください。
                      ﾌﾟｰﾙサービス（監視・清掃）と水泳指導・体操などのお仕事です！水泳指導・・・・
                      研修後インストラクターとして活躍していただきます！未経験OK！時給1.000円以上！
                      <br />
                      <div
                        style={{
                          width: "100%",
                          color: "red",
                          fontSize: "1.3em",
                        }}
                      >
                        土日・平日午後できる方大募集！
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6}>
                <TableStyled size="sm" striped bordered>
                  <tr>
                    <th>募集職種</th>
                    <td>
                      ﾌﾟｰﾙサービススタッフ
                      <br />
                      水泳指導インストラクター
                      <br />
                      フロントスタッフ
                    </td>
                  </tr>

                  <tr>
                    <th>応募資格</th>
                    <td>
                      高卒以上（未経験可）
                      <br /> インストラクター・・・・経験者優遇
                    </td>
                  </tr>

                  <tr>
                    <th>給与・待遇</th>
                    <td>
                      ﾌﾟｰﾙサービススタッフ → 960円〜
                      <br />
                      水泳指導インストラクター → 1.000円〜
                      <br />
                      フロントスタッフ → 1.000円〜
                      <br />
                      ※日曜日は希望制 → 1.000円
                    </td>
                  </tr>

                  <tr>
                    <th>シフト</th>
                    <td>
                      9:30 >> 18:00 <br />
                      15:00 >> 21:00 <br />
                      週1回、2時間からでもOK！
                    </td>
                  </tr>

                  <tr>
                    <th>連絡先</th>
                    <td> 採用担当　関口まで　　04-2959-2122</td>
                  </tr>
                </TableStyled>
              </Col>
            </Row>
          </Container>
        </Section>
      </Layout>
    </>
  );
};
export default RecruitPage;
