import React from "react";
import styled from "styled-components";
import Table from "react-bootstrap/Table";
import { colors } from "../../utils/styles";

const TableStyled = styled(Table)`
  border: solid 1px ${colors.sscBlue};
  & th {
    color: ${colors.sscWhite};
    background-color: ${colors.sscBlue};
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    border: solid 1px ${colors.sscWhite};
    vertical-align: middle;
  }

  & td {
    background-color: ${colors.sscWhite};
    border: solid 1px ${colors.sscGray};
  }
`;

export default TableStyled;
